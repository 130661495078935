import {
  Backdrop,
  Box,
  ClickAwayListener,
  Icon,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
} from '@mui/material';
import {
  ProductBillingType,
  SingleInvoiceAssignedProduct,
  useGetProductsListQuery,
} from '@schooly/api';
import { ExpandedSelect, SelectSearchInput } from '@schooly/components/filters';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  ChevronDownSmallIcon,
  NewTabIcon,
  SimpleButton,
  SkeletonRowsComponent,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { FC, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { SelectRow } from '../../../../../../components/common/Invoices/PayerDropdown';
import { HOVER_TEXT_CLASS_NAME } from '../../../../../../pages/School/SchoolProducts/SchoolProductCreateModal/CreateVariantsTable';

type ProductDropdownProps = {
  relationId: string;
  schoolId: string;
  onSelect: (product: SingleInvoiceAssignedProduct) => void;
  selectedProduct?: SingleInvoiceAssignedProduct;
  opened?: boolean;
  onClose?: () => void;
  onClear?: () => void;
  placeholder?: string;
  legalEntityIds?: string[];
  disabled?: boolean;
} & Partial<Omit<TooltipProps, 'children' | 'onSelect'>>;

const PRODUCT_PAGE_SIZE = 20;

export const ProductDropdown: FC<ProductDropdownProps> = ({
  onSelect,
  selectedProduct,
  opened: initialOpened,
  onClose,
  placeholder,
  schoolId,
  relationId,
  onClear,
  legalEntityIds,
  disabled,
  ...props
}) => {
  const [opened, open, close] = useFlag(initialOpened);
  const inputRef = useRef<HTMLInputElement>(null);
  const { formatMessage } = useIntl();

  const { isLoading, isFetching, data, setParams, ...requestProps } = useGetProductsListQuery(
    {
      schoolId,
      query: '',
      pageSize: PRODUCT_PAGE_SIZE,
      relationId,
      legalEntityIds: legalEntityIds,
      type: ProductBillingType.OneOff,
    },
    { refetchOnMount: 'always', enabled: !disabled },
  );

  const isProductsLoading = isLoading || (!data?.pages.length && isFetching);

  const handleChangeQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleClose = useCallback(() => {
    close();
    onClose?.();
  }, [close, onClose]);

  const renderContent = useCallback(() => {
    if (isProductsLoading) {
      return <SkeletonRowsComponent height={20} rowCount={3} gap={1.5} p={1.5} />;
    }

    const entries =
      data?.pages.reduce<SingleInvoiceAssignedProduct[]>((prev, products) => {
        const productTypesWithVariants = products.results.reduce<SingleInvoiceAssignedProduct[]>(
          (acc, { types, id, name, type }) =>
            type === 'one_off'
              ? [
                  ...acc,
                  ...types.flatMap((t) =>
                    t.variants.map(({ id: variant_id, prices }) => ({
                      id,
                      name,
                      variant_id,
                      type_id: t.id,
                      type_name: t.name,
                      //In one-off product we have only 1 price with one-off frequency
                      price: prices[0].price,
                      billing_connection: t.billing_connection,
                      quantity: 1,
                    })),
                  ),
                ]
              : acc,
          [],
        );

        return [...prev, ...productTypesWithVariants];
      }, []) ?? [];

    if (!entries?.length) {
      return (
        <Stack p={1.75} gap={1}>
          <Typography>{formatMessage({ id: 'singleInvoices-NoProducts' })}</Typography>

          <Box component={Link} to="/settings/products" target="_blank" onClick={handleClose}>
            <SimpleButton size="small" startIcon={<NewTabIcon />}>
              {formatMessage({ id: 'singleInvoices-ProductsPage' })}
            </SimpleButton>
          </Box>
        </Stack>
      );
    }

    return (
      <Stack
        px={1}
        py={0.5}
        sx={{
          '.layout': {
            py: 0.5,
          },
        }}
      >
        {entries.map((p) => {
          const isSelected = selectedProduct?.variant_id === p.variant_id;

          return (
            <SelectRow
              key={p.variant_id}
              onSelect={() => {
                onSelect(p);
                close();
              }}
              isSelected={isSelected}
            >
              <TypographyWithOverflowHint
                color={!isSelected ? 'common.grey2' : undefined}
                variant="h3"
                noWrap
              >
                {p.name} {p.type_name}
              </TypographyWithOverflowHint>
            </SelectRow>
          );
        })}
      </Stack>
    );
  }, [
    isProductsLoading,
    data?.pages,
    formatMessage,
    handleClose,
    selectedProduct?.variant_id,
    onSelect,
    close,
  ]);

  return (
    <>
      <Backdrop open={opened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <Tooltip
            onClose={handleClose}
            open={opened}
            placement="bottom-start"
            PopperProps={{
              disablePortal: true,
            }}
            componentsProps={{
              tooltip: {
                sx: (theme) => ({
                  width: 495,
                  maxWidth: 495,
                  borderRadius: theme.spacing(1),
                  border: `1px solid ${theme.palette.common.light3}`,
                  padding: 0,
                  overflow: 'hidden',
                  margin: `0 !important`,
                  borderTopLeftRadius: 0,
                }),
              },
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <ExpandedSelect
                width={495}
                renderContent={renderContent}
                onClose={handleClose}
                onClickInputArea={() => inputRef.current?.focus()}
                hasSelectedValue={!!selectedProduct}
                onClear={onClear}
                {...requestProps}
              >
                <Box height={34} width="100%">
                  {placeholder && (
                    <Typography
                      color="common.grey"
                      sx={{
                        position: 'absolute',
                        top: 2,
                      }}
                    >
                      {placeholder}
                    </Typography>
                  )}

                  <Stack direction="row" pt={placeholder ? 1.75 : 1} width="100%">
                    <Typography variant="h3" whiteSpace="nowrap">
                      {selectedProduct?.name} {selectedProduct?.type_name}
                    </Typography>
                    <SelectSearchInput
                      ref={inputRef}
                      autoFocus
                      value={requestProps.params.query || ''}
                      onChangeText={handleChangeQuery}
                      sx={(theme) => ({
                        ml: selectedProduct ? 1 : 0,
                        width: '100%',
                        ...theme.typography.h3,
                      })}
                    />
                  </Stack>
                </Box>
              </ExpandedSelect>
            }
            {...props}
          >
            <Box
              onClick={opened ? handleClose : open}
              position="relative"
              sx={{
                pointerEvents: disabled ? 'none' : 'inherit',
                cursor: 'pointer',
                zIndex: (theme) => (opened ? theme.zIndex.drawer + 2 : undefined),
              }}
            >
              {!opened ? (
                <Stack width="100%" direction="row" justifyContent="space-between">
                  <TypographyWithOverflowHint variant="h3" color="common.grey2">
                    {selectedProduct?.name} {selectedProduct?.type_name}
                  </TypographyWithOverflowHint>
                  {!disabled && (
                    <Icon sx={{ color: 'common.grey' }} className={HOVER_TEXT_CLASS_NAME}>
                      <ChevronDownSmallIcon />
                    </Icon>
                  )}
                </Stack>
              ) : (
                <></>
              )}
            </Box>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};
