import {
  Avatar,
  Backdrop,
  Box,
  ClickAwayListener,
  Icon,
  IconButton,
  Stack,
  StackProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { Company, WithAvatar, WithName } from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { ExpandedSelect } from '@schooly/components/filters';
import { useFlag } from '@schooly/hooks/use-flag';
import { ArrowDownV2Icon, CompanyIcon, DoneIcon, TypographyWithOverflowHint } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { FC, PropsWithChildren, ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { getPayerId } from './utils';

export type Payer<T> = (Company & { type: 'company' }) | (T & { type: 'guardian' });

type AbstractGuardian = {
  relation_id: string;
} & WithName &
  WithAvatar;

type PayerDropdownProps<T> = {
  selectedPayer: Payer<T>;
  renderDropdownContent: (close: () => void) => ReactNode;
  renderMainContent?: () => ReactNode;
  payers?: Payer<T>[];
  onSelect?: (p: Payer<T>) => void;
  onToggle?: (v: boolean) => void;
};

export const PayerDropdown = <T extends AbstractGuardian>({
  selectedPayer,
  payers,
  renderDropdownContent,
  renderMainContent,
  onSelect,
  onToggle,
}: PayerDropdownProps<T>) => {
  const [opened, open, close] = useFlag();
  const { formatMessage } = useIntl();

  const renderContent = useCallback(() => {
    if (!payers) return <Typography>{formatMessage({ id: 'input-NoOptionsFound' })}</Typography>;
    return payers.map((p) => (
      <SelectRow
        key={getPayerId(p)}
        onSelect={() => {
          onSelect?.(p);
          close();
        }}
        isSelected={selectedPayer.type === 'company' && selectedPayer.id === getPayerId(p)}
      >
        <PayerContent payer={p} />
      </SelectRow>
    ));
  }, [close, formatMessage, onSelect, payers, selectedPayer]);

  const handleOpen = useCallback(() => {
    open();
    onToggle?.(true);
  }, [onToggle, open]);

  const handleClose = useCallback(() => {
    close();
    onToggle?.(false);
  }, [close, onToggle]);

  return (
    <>
      <Backdrop open={opened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
      <ClickAwayListener onClickAway={close}>
        <div>
          <Tooltip
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [-12, -41],
                  },
                },
              ],
            }}
            onClose={close}
            open={opened}
            placement="bottom-start"
            componentsProps={{
              tooltip: {
                sx: (theme) => ({
                  width: 475,
                  maxWidth: 475,
                  borderRadius: theme.spacing(1),
                  border: `1px solid ${theme.palette.common.light3}`,
                  padding: 0,
                  overflow: 'hidden',
                  margin: '0 !important',
                }),
              },
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <Box
                sx={{
                  '.header': {
                    minHeight: '43px',
                  },
                }}
              >
                <ExpandedSelect
                  width={475}
                  hasSelectedValue={!!selectedPayer}
                  renderContent={() =>
                    renderDropdownContent ? renderDropdownContent(close) : renderContent()
                  }
                  onClose={handleClose}
                >
                  <PayerContent
                    labelText={formatMessage({ id: 'profile-Payer' })}
                    pl={0.5}
                    payer={selectedPayer}
                    isSelected
                  />
                </ExpandedSelect>
              </Box>
            }
          >
            <Stack
              onClick={opened ? handleClose : handleOpen}
              direction="row"
              sx={(theme) => ({
                cursor: 'pointer',
                zIndex: opened ? theme.zIndex.drawer + 2 : undefined,
                ':hover .arrowDropdown': {
                  path: {
                    stroke: theme.palette.primary.main,
                  },
                },
              })}
              alignItems="center"
              gap={1.75}
            >
              {renderMainContent ? (
                renderMainContent()
              ) : (
                <PayerContent
                  labelText={formatMessage({ id: 'profile-Payer' })}
                  payer={selectedPayer}
                  isSelected
                >
                  <IconButton
                    sx={(theme) => ({
                      width: theme.spacing(1),
                      path: {
                        stroke: theme.palette.common.grey,
                      },
                    })}
                  >
                    <ArrowDownV2Icon className="arrowDropdown" />
                  </IconButton>
                </PayerContent>
              )}
            </Stack>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

type SelectRowProps = {
  onSelect: () => void;
  isSelected?: boolean;
};

export const SelectRow: FC<PropsWithChildren<SelectRowProps>> = ({
  onSelect,
  isSelected,
  children,
}) => {
  return (
    <Stack
      className="layout"
      onClick={onSelect}
      flexDirection="row"
      alignItems="center"
      px={0.5}
      gap={2}
      py={0.75}
      sx={(theme) => ({
        cursor: 'pointer',
        justifyContent: 'space-between',

        ...(isSelected && {
          '.MuiTypography-root': {
            color: 'primary.main',
          },
        }),
        '&:hover': {
          backgroundColor: theme.palette.background.default,
          borderRadius: 0.5,
          '.MuiTypography-root': {
            color: 'primary.main',
          },
        },
      })}
    >
      {children}
      <Icon
        sx={{
          visibility: isSelected ? 'visible' : 'hidden',
        }}
      >
        <DoneIcon />
      </Icon>
    </Stack>
  );
};

type PayerContentProps = {
  payer: Payer<AbstractGuardian>;
  isSelected?: boolean;
  labelText?: string;
  avatarTooltipTitle?: string;
} & StackProps;

export const PayerContent: FC<PropsWithChildren<PayerContentProps>> = ({
  payer,
  isSelected,
  children,
  labelText,
  avatarTooltipTitle = '',
  ...props
}) => {
  return (
    <Stack direction="row" gap={1} alignItems="center" {...props}>
      <Tooltip
        title={avatarTooltipTitle}
        componentsProps={{
          tooltip: {
            sx: (theme) => ({
              padding: theme.spacing(1.25),
              width: 200,
            }),
          },
        }}
      >
        <span>
          {payer.type === 'company' ? (
            <Avatar
              sx={(theme) => ({
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.primary.main,
              })}
            >
              <CompanyIcon />
            </Avatar>
          ) : (
            <AvatarAuth user={payer} />
          )}
        </span>
      </Tooltip>

      <Stack>
        {labelText && (
          <Typography variant="caption" color="common.grey">
            {labelText}
          </Typography>
        )}

        <Stack direction="row" gap={2}>
          <TypographyWithOverflowHint>
            <Typography
              display="inline"
              flex={1}
              color={!isSelected ? 'common.grey2' : undefined}
              variant="h3"
              noWrap
            >
              {payer.type === 'company' ? payer.name : getUserFullName(payer)}
            </Typography>
          </TypographyWithOverflowHint>

          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};
