import { IconButton, Skeleton, Stack, Typography } from '@mui/material';
import {
  GET_PAYABLE_FEES,
  GET_PAYABLE_FEES_STATISTICS,
  MAX_PAGE_SIZE,
  PayableFee,
  PayableFeeStatus,
  SchoolYear,
  SORT_DIRECTION,
  StudentForCompany,
  useGetDependantStudentsForParentQuery,
  useGetPayableFeesQuery,
  useGetStudentsForCompanyListQuery,
  useInvoiceActionMutation,
  UserType,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import {
  FilterButtonDropdown,
  SelectContentSkeleton,
  UserSelectRow,
} from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { Currencies, SchoolUserRole } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  ChartIcon,
  Counter,
  DropdownYears,
  Loading,
  ModalSearch,
  PlusIcon,
  SimpleButton,
  TagUser,
} from '@schooly/style';
import React, { FC, PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { WithRef } from '../../..//hooks/useWithRef';
import { useProfile } from '../../../context/profile/useProfile';
import useSchoolYears from '../../../hooks/useSchoolYears';
import { queryClient } from '../../../queryClient';
import { getStudentName } from './helpers';
import { PayableFeesChart } from './PayableFeesCharts';
import { PayableFeesContent } from './PayableFeesContent';

export type PayableFeesRelationType = UserType | 'company';

type PayableFeesProps = {
  id: string;
  relationType: PayableFeesRelationType;
  schoolYears?: SchoolYear[];
};

export type PayableFeeFilter = { status: PayableFeeStatus; currency?: Currencies };

export const PayableFees: FC<PayableFeesProps> = ({
  id,
  relationType,
  schoolYears: initialSchoolYears,
}) => {
  const { $t, formatMessage } = useIntl();
  const { permissions, schoolId = '' } = useAuth();
  const containerRef = useRef<HTMLDivElement>(null);

  const isInvoiceCreator = permissions.includes('product_and_invoice_creator');

  const { defaultValidity, schoolYears: years } = useSchoolYears();
  const [isChartsOpened, openCharts, closeCharts] = useFlag(true);
  const [filter, setFilter] = useState<PayableFeeFilter>();
  const schoolYears = initialSchoolYears ?? years;
  const isParent = relationType === 'parent';
  const isCompany = relationType === 'company';
  const yearFromProps =
    initialSchoolYears?.find((y) => y.id === defaultValidity?.id) || initialSchoolYears?.[0];
  const initialYearId = yearFromProps?.id ?? defaultValidity?.id;
  const { schoolMembership } = useProfile();
  const navigate = useNavigate();
  const year_id = initialYearId!;

  const { data, isLoading, setParams, params } = useGetPayableFeesQuery(
    {
      year_id,
      school_ids: schoolId!,
      page_size: MAX_PAGE_SIZE,
      query: '',
      filters: {
        ...(isCompany ? { company_ids: [id] } : { relation_ids: [id] }),
      },
      sort: {
        by: 'issue_date',
        direction: SORT_DIRECTION.DESC,
      },
    },
    { refetchOnMount: 'always', enabled: !!initialYearId && !!schoolId },
  );

  const statisticsProps = useMemo(
    () => ({
      selectedStatus: filter?.status,
      school_ids: params.school_ids,
      year_id: params.year_id || year_id,
      relation_ids: params.filters?.relation_ids,
      company_ids: params.filters?.company_ids,
    }),
    [
      filter?.status,
      params.filters?.company_ids,
      params.filters?.relation_ids,
      params.school_ids,
      params.year_id,
      year_id,
    ],
  );

  const { showError, showNotification } = useNotifications();
  const invoiceAction = useInvoiceActionMutation();

  const handleVoidInvoice = useCallback(
    (invoiceId: string) => {
      return invoiceAction.mutateAsync(
        { invoice_ids: [invoiceId], action: 'void' },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([GET_PAYABLE_FEES_STATISTICS, statisticsProps]);
            queryClient.invalidateQueries([GET_PAYABLE_FEES, params]);
            showNotification({
              textId: 'payableFees-InvoiceUpdated',
              type: 'success',
            });
          },
          onError: showError,
        },
      );
    },
    [invoiceAction, params, showError, showNotification, statisticsProps],
  );

  const isCurrentYearSelected = !!defaultValidity && defaultValidity.id === params.year_id;

  const selectedId = params.filters?.relation_ids?.[1];

  const selectedYear = useMemo(
    () => schoolYears.find((y) => y.id === params.year_id),
    [params.year_id, schoolYears],
  );

  const handleYearChange = useCallback(
    (schoolYear: SchoolYear) => {
      setParams((p) => ({ ...p, year_id: schoolYear.id }));
    },
    [setParams],
  );

  const handleQueryChange = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleSelectRelationChange = useCallback(
    (filterId: string) => {
      setParams((p) => ({
        ...p,
        relation_ids: selectedId === filterId ? [id] : [id, filterId],
      }));
    },
    [id, selectedId, setParams],
  );

  const filteredEntries = useMemo(() => {
    const entries =
      data?.pages.reduce<PayableFee[]>((prev, curr) => [...prev, ...curr.results], []) ?? [];

    return filter && entries
      ? entries.filter((e) => e.status === filter.status && e.currency === filter.currency)
      : entries;
  }, [data?.pages, filter]);

  const canCreateSingleInvoices =
    isInvoiceCreator &&
    (schoolMembership?.role === SchoolUserRole.Student ||
      schoolMembership?.role === SchoolUserRole.Parent);

  if (!schoolId) return null;

  return (
    <Stack height="100%" gap={3}>
      <Stack height="100%" gap={1.75} marginTop={-0.75}>
        <Stack direction="row" justifyContent="space-between" minHeight={40}>
          <PayableFeesHeaderTitle count={filteredEntries.length} />

          <Stack direction="row" gap={2.5} alignItems="center">
            <ModalSearch
              value={params.query ?? ''}
              onChange_MemoizedCallbackOnly={handleQueryChange}
              placeholder={$t({ id: 'people-Search' })}
              withDebounce
            />
            {isCompany && selectedYear?.id && (
              <CompanyStudentSelect
                companyId={id}
                selectedId={selectedId}
                onSelect={handleSelectRelationChange}
                yearId={selectedYear.id}
              />
            )}
            <DropdownYears
              years={schoolYears}
              defaultYear={defaultValidity}
              currentYear={selectedYear}
              onYearChange={handleYearChange}
            />
            <IconButton sx={{ color: isChartsOpened ? 'common.grey2' : 'common.grey' }} inverse>
              <ChartIcon onClick={isChartsOpened ? closeCharts : openCharts} />
            </IconButton>

            {canCreateSingleInvoices && (
              <SimpleButton
                startIcon={<PlusIcon />}
                onClick={() => navigate({ pathname: 'single-invoices' })}
              >
                {formatMessage({ id: 'singleInvoices-AddSingleInvoice' })}
              </SimpleButton>
            )}
          </Stack>
        </Stack>

        {isParent && (
          <ParentStudentsSelect
            onSelect={handleSelectRelationChange}
            requestId={id}
            yearId={params.year_id || year_id}
            selectedId={selectedId}
          />
        )}

        <WithRef containerRef={containerRef}>
          <Stack
            gap={2.5}
            ref={containerRef}
            sx={(theme) => ({
              overflowY: 'scroll',
              height: '100%',
              [theme.breakpoints.down('md')]: {
                mr: -2.5,
              },
            })}
          >
            {isChartsOpened && params.year_id && (
              <PayableFeesChart
                onCloseChart={closeCharts}
                onSetFilter={(f) =>
                  setFilter((currFilter) => (currFilter?.status === f?.status ? undefined : f))
                }
                columns={3}
                {...statisticsProps}
              />
            )}

            {isLoading || !data ? (
              <Loading />
            ) : (
              <PayableFeesContent
                data={filteredEntries}
                relationType={relationType}
                isCurrentYearSelected={isCurrentYearSelected}
                schoolId={schoolId}
                onVoidInvoice={handleVoidInvoice}
              />
            )}
          </Stack>
        </WithRef>
      </Stack>
    </Stack>
  );
};

type PayableFeesHeaderTitleProps = {
  count?: number;
};

export const PayableFeesHeaderTitle: FC<PayableFeesHeaderTitleProps> = ({ count }) => {
  const { $t } = useIntl();
  return (
    <Stack direction="row" alignItems="center">
      <Typography variant="h2">{$t({ id: 'profile-PayableFees' })}</Typography>
      {!!count && <Counter>{count}</Counter>}
    </Stack>
  );
};

type CompanyStudentSelectProps = PropsWithChildren<{
  selectedId?: string;
  onSelect: (v: string) => void;
  companyId: string;
  yearId?: string;
}>;

export const CompanyStudentSelect: FC<CompanyStudentSelectProps> = ({
  selectedId,
  onSelect,
  companyId,
  yearId,
}) => {
  const { data, hasNextPage, params, setParams, isLoading, isFetchingNextPage, fetchNextPage } =
    useGetStudentsForCompanyListQuery(
      {
        companyId,
        query: '',
        year_id: yearId,
      },
      {
        refetchOnMount: 'always',
      },
    );

  const handleChangeQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const renderContent = useCallback(
    (onClose: () => void) => {
      if (!data) return <SelectContentSkeleton />;

      const entries =
        data.pages.reduce<StudentForCompany[]>((prev, curr) => [...prev, ...curr.results], []) ??
        [];

      if (!entries.length)
        return (
          <Typography p={1}>
            <FormattedMessage id="input-NoOptionsFound" />
          </Typography>
        );

      return (
        <>
          {entries.map((s) => (
            <UserSelectRow
              onClick={() => {
                onClose();
                onSelect(s.relation.id);
                handleChangeQuery('');
              }}
              key={s.relation.id}
              user={s.relation}
              isSelected={s.relation.id === selectedId}
            />
          ))}
        </>
      );
    },
    [data, handleChangeQuery, onSelect, selectedId],
  );

  return (
    <FilterButtonDropdown
      hasSelectedValue={!!selectedId}
      renderContent={renderContent}
      query={params.query ?? ''}
      onChangeQuery={handleChangeQuery}
      isFetchingNextPage={isLoading || isFetchingNextPage}
      hasNextPage={hasNextPage}
      onFetchNextPage={fetchNextPage}
    />
  );
};
type ParentStudentsSelectProps = {
  requestId: string;
  yearId: string;
  selectedId?: string;
  onSelect?: (id: string) => void;
};

const ParentStudentsSelect: FC<ParentStudentsSelectProps> = ({
  requestId,
  selectedId,
  yearId,
  onSelect,
}) => {
  const { data: students, isLoading: isLoadingStudents } = useGetDependantStudentsForParentQuery({
    id: requestId,
    year_id: yearId,
  });

  if (students && students.length <= 1) return null;
  return (
    <Stack direction="row" gap={1} flexWrap="wrap" mb={2.5}>
      {isLoadingStudents || !students
        ? [...new Array(3)].map(() => (
            <Skeleton
              variant="rectangular"
              width={120}
              height={44}
              sx={{ '&&': { borderRadius: 100 } }}
            />
          ))
        : students?.map((student) => {
            const selected = selectedId === student.id;

            return (
              <TagUser
                selected={selected}
                avatar={<AvatarAuth user={student} />}
                label={getStudentName(student)}
                onClick={() => onSelect?.(student.id)}
              />
            );
          })}
    </Stack>
  );
};
